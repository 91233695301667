import React, {Fragment, useEffect, useState} from 'react'
import ApiRest from "../../service/ApiRest";
import {useHistory} from "react-router-dom";
import {toast} from "react-toastify";
import BtnEdit from "./components/BtnEdit";
import BtnRemove from "./components/BtnRemove";
import BtnRedirect from "./components/BtnRedirect";
import {useUser} from "../../Components/User/UserProvider";

export interface UrlShortener {
    id: number,
    title: string,
    sourceUrl: string,
    shortUrl: string,
    utmSource: string,
    utmMedium: string,
    utmCampaign: string,
    adContent: string,
    sourceUrlB: string,
    urlRatioA: number,
    createdAt: string,
    clicks: number,
}

const makeId = (length: number) => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() *
            charactersLength));
    }
    return result;
}

export default function UrlShortenerListPage() {
    const history = useHistory();

    const {user} = useUser();

    const [shortUrl, onShortUrlChange] = React.useState('');
    const [data, setData] = useState<UrlShortener[]>([]);
    const [isDataLoaded, setIsDataLoaded] = useState(false);

    const goToLink = (e: React.MouseEvent, id: number) => {
        e.preventDefault();
        history.push('/url-shortener/' + id);
    }

    const getData = () => {
        ApiRest.getElements('url-shortener').then((res) => {
            if (res) {
                setData(res);
                setIsDataLoaded(true);
            }
        })
    }
    useEffect(getData, []);

    const createNew = () => {

        if (!shortUrl) return;
        ApiRest.addElement('url-shortener', {
            title: 'Auto generated title',
            sourceUrl: shortUrl,
            shortUrl: makeId(6)
        }).then((res) => {
            toast.success('Success!');
            history.push('/url-shortener/' + res.id);
        })
    }

    const hasSubscription = user.hasSubscription?true:true;

    return (
        <Fragment>

        <div className={"space-y-8 p-8 relative"} style={hasSubscription?{}:{filter: 'blur(3px)'}}>
            <div className={"flex gap-6 items-center px-10"}>
                <span>Enter long url</span>
                <span className={"flex-grow"}>
                    <input type="text" placeholder=""
                           className={"bg-white"}
                           value={shortUrl}
                           onChange={(e) => onShortUrlChange(e.target.value)}
                    />
                </span>
                <button className={"main-btn"} onClick={createNew}>SHORTEN URL</button>
            </div>
            {isDataLoaded &&
            <table className={"table-auto"}>
                <thead>
                <tr>
                    <th>Title</th>
                    <th>Shortened URL</th>
                    <th>Date created</th>
                    <th>Clicks</th>
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                {data.map((item: UrlShortener) => {
                    const redirectUrl = 'https://cpbx.me/' + item.shortUrl;
                    return (
                        <tr key={"i-" + item.id}>
                            <td onClick={(e) => goToLink(e, item.id)}
                                className="font-semibold cursor-pointer">{item.title}</td>
                            <td>
                                {redirectUrl}
                            </td>
                            <td>
                                {item.createdAt}
                            </td>
                            <td>
                                {item.clicks}
                            </td>

                            <td className={"w-40"}>
                                <div className={"space-x-5"}>
                                    {/*<BtnStats item={item}/>*/}
                                    <BtnRedirect item={item}/>
                                    <BtnEdit item={item}/>
                                    <BtnRemove reload={getData} item={item}/>
                                </div>
                            </td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
            }
        </div>
            {!hasSubscription &&
            <div className={"text-red-500 text-center text-xl"}>Subscribe to use URL shortener</div>
            }
        </Fragment>
    )
}

import React, {Fragment, useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import ApiRest from "../../service/ApiRest";
import {Funnel} from "../campaigns/CampaignsListPage";

export interface User {
    id: number,
    email: string,
    createdAt: string,
    trialTill: string,
    campaigns: Funnel[],
    isValid: boolean,
    validTill: string
}

export default function UsersListPage() {
    const history = useHistory();
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState('');

    const [searchLocal, setSearchLocal] = useState('');

    const [data, setData] = useState<User[]>([]);
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const goToLink = (e: React.MouseEvent, id: number) => {
        e.preventDefault();
        history.push('/admin/user/' + id);
    }
    const getData = () => {
        ApiRest.getElements('user', page, search).then((res) => {
            if (res) {
                setData(res);
                setIsDataLoaded(true);
            }
        })
    }
    useEffect(getData, [page, search]);

    const doSearch = (e: any) => {
        e.preventDefault();

        setSearch(searchLocal);
        setPage(1);
    }

    if (!isDataLoaded) {
        return <Fragment/>
    }

    return (
        <Fragment>
            {

                <Fragment>
                    <div className={"space-y-8 p-8"}>
                        <div className={"flex"}>
                            <label className={"w-40"}>Search</label>
                            <span className={"flex-grow"}>
                                <input type="text" placeholder="" value={searchLocal}
                                       onChange={(e) => setSearchLocal(e.target.value)}
                                />
                            </span>
                            <button onClick={doSearch} className="main-btn ml-4">Search</button>
                        </div>


                        <table>
                            <thead>
                            <tr>
                                <th>ID</th>
                                <th>Email</th>
                                <th>Trial</th>
                                <th>Created</th>
                                <th>Campaigns</th>
                                <th>Valid</th>
                            </tr>
                            </thead>
                            <tbody>
                            {data.map((item: User) => {
                                return (
                                    <tr key={"i-" + item.id}>
                                        <td onClick={(e) => goToLink(e, item.id)}
                                            className="cursor-pointer underline hover:no-underline">{item.id}</td>
                                        <td onClick={(e) => goToLink(e, item.id)}
                                            className="cursor-pointer underline hover:no-underline">{item.email}</td>
                                        <td onClick={(e) => goToLink(e, item.id)}
                                            className="cursor-pointer underline hover:no-underline">
                                            {item.createdAt}
                                        </td>
                                        <td onClick={(e) => goToLink(e, item.id)}
                                            className="cursor-pointer underline hover:no-underline">
                                            {item.trialTill}
                                        </td>
                                        <td onClick={(e) => goToLink(e, item.id)}
                                            className="cursor-pointer underline hover:no-underline">
                                            {item.campaigns.length}
                                        </td>
                                        <td>
                                            {item.isValid ? 'Valid till ' + item.validTill : '---'}
                                        </td>

                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                        <div className={"flex gap-4"}>
                            {page > 1 &&
                            <button type={"button"} onClick={() => setPage(page - 1)} className={"main-btn"}>prev</button>
                            }
                            <button type={"button"} onClick={() => setPage(page + 1)} className={"main-btn"}>next</button>
                        </div>
                    </div>
                </Fragment>
            }
        </Fragment>
    )
}

import React from "react";

import Logo from "../../../assets/images/logo.png"
import AppMenu from "./AppMenu";
// import {useSelector} from "react-redux";
// import {IStore} from "../../../types/redux.types";

// const Parse = require('parse');

export default function AppHeader() {
    // const reloadUser = useSelector((state : IStore) => state.Reload.user);
    // const currentUser = Parse.User.current();

    // const avatar = currentUser.get('avatar')?currentUser.get('avatar').url():UserA;

    return (
        <div className={"w-80 pt-5 pl-5 bg-coal min-h-screen"}>
            <a href="./">
                <img width={150} src={Logo} alt={""}  className={"mb-10"}/>
            </a>

            <AppMenu/>
        </div>
    )
}

class ApiCampaignService {
    private baseUrl: string;

    constructor() {
        this.baseUrl = '/app/campaign';
        if (process.env.NODE_ENV === "development") {
            this.baseUrl = 'http://localhost:8000/app/campaign';
        }
    }

    authRequestOptions = () => {
        let token: string = '';
        const localToken = localStorage.getItem('token');
        if (localToken !== null) {
            token = localToken;
        }
        return {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }
    };

    sendTestEvent = (accessToken: string, pixelId: string, testEventCode: string) => {
        const requestOptions = {
            ...this.authRequestOptions(),
            body: JSON.stringify({accessToken, pixelId, testEventCode})
        };

        return fetch(this.baseUrl + '/testEvent', requestOptions).then(res => res.json());
    }
}

const ApiCampaign = new ApiCampaignService();
export default ApiCampaign;

import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import Field from "../../form/Field";
import {toast} from "react-toastify";
import LogoBlack from "../../assets/images/logo_black.png";
import AuthMobileAlert from "./AuthMobileAlert";
import NaeApiAuth from "../../service/NaeApiAuth";
import PaymentPage from "../payments/PaymentPage";
import PaymentSubscriptionPage from "../payments/PaymentSubscriptionPage";


export default function SignupSubscriptionPage() {
    const history = useHistory();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordRepeat, setPasswordRepeat] = useState('');

    const [step, setStep] = useState(1);


    const goToLogin = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        history.push('/login');
    }

    const goToStep2 = () => {
        if (!email.length || !password.length || !passwordRepeat.length) {
            toast.error('Please fill all required fields');
            return;
        }
        if (password !== passwordRepeat) {
            toast.error('Passwords don\'t match');
            return;
        }
        setStep(2);
    }

    const signUp = () => {
        if (!email.length || !password.length || !passwordRepeat.length) {
            toast.error('Please fill all required fields');
            return;
        }
        if (password !== passwordRepeat) {
            toast.error('Passwords don\'t match');
            return;
        }

        NaeApiAuth.doRegister(email, password, passwordRepeat).then((res) => {
            if (res.isError) {
                toast.error(res.error.description)
                return
            }
            window.localStorage.setItem('token', res.token);

            history.push('/funnels');

            try {
                // @ts-ignore
                window.dataLayer.push({
                    'event': 'sign_up'
                });

            } catch (e) {
            }

            try {
                // @ts-ignore
                AffTrackingClass.addCustomer(email);
            } catch (e) {
            }
            // window.location.href = '/successful-registration';
        })
    }

    return (
        <div className={"main-bg h-screen w-screen"}>
            <div className={"h-screen flex justify-center items-center"}>
                {step === 1 ?
                    <div className={"w-96 px-8 py-10 bg-white shadow-md rounded-md grid gap-2"}>
                        <AuthMobileAlert/>

                        <div className={"text-center mb-4"}>
                            <img src={LogoBlack} width={150} className={"mx-auto mb-8"} alt={""}/>
                            <p className={"text-lg font-medium"}>Welcome!</p>
                            <p className={"text-sm font-medium"}>Sign up to activate your 14 day FREE trial and improve your business</p>
                        </div>

                        <Field title={"Enter email address"} fieldKey={"email"} val={email}
                               onChange={(key: string, val: string) => setEmail(val)}
                               required={true}
                               hideLabel={true}/>

                        <Field title={"Enter password"} fieldKey={"password"} val={password}
                               onChange={(key: string, val: string) => setPassword(val)} type={"password"}
                               required={true}
                               hideLabel={true}/>

                        <Field title={"Repeat password"} fieldKey={"passwordRepeat"} val={passwordRepeat}
                               onChange={(key: string, val: string) => setPasswordRepeat(val)} type={"password"}
                               required={true}
                               hideLabel={true}/>


                        <button type="button" className={"main-btn mt-4"} onClick={goToStep2}>Sign Up</button>

                        <p className={"text-center mt-4 text-xs"}>Already have an account? <a href="/login"
                                                                                              className={"text-sm"}
                                                                                              onClick={goToLogin}>Log
                            in</a></p>

                    </div>
                    :
                    <div className={"data-container-subscribe px-8 py-10 bg-white shadow-md rounded-md grid gap-2"}>
                        <div className={"text-center mb-4"}>
                            <img src={LogoBlack} width={150} className={"mx-auto mb-8"} alt={""}/>
                            {/*<p className={"text-lg font-medium"}>Welcome!<br/> Nice to see you</p>*/}
                            {/*<p className={"text-sm font-medium"}>Sign up and improve your business</p>*/}
                            <p className={"text-lg font-medium"}>Welcome!</p>
                            <p className={"text-sm font-medium"}>Sign up to activate your 14 day FREE trial and improve your business</p>
                        </div>
                        <PaymentSubscriptionPage backToStep1={() => setStep(1)} email={email} password={password} passwordRepeat={passwordRepeat} containerClassName={"data-container-subscribe"}/>
                    </div>
                }
            </div>
        </div>
    )
}

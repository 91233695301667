class ApiProfileService {
    private baseUrl: string;

    constructor() {
        this.baseUrl = '/app/profile';
        if (process.env.NODE_ENV === "development") {
            this.baseUrl = 'http://localhost:8000/app/profile';
        }
    }

    authRequestOptions = () => {
        let token: string = '';
        const localToken = localStorage.getItem('token');
        if (localToken !== null) {
            token = localToken;
        }
        return {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }
    };

    authUploadRequestOptions = () => {
        let token: string = '';
        const localToken = localStorage.getItem('token');
        if (localToken !== null) {
            token = localToken;
        }
        return {
            method: 'POST',
            headers: {
                'Authorization': token
            },
        }
    };
    getProfile = () => {
        const requestOptions = {
            ...this.authRequestOptions(),
            body: JSON.stringify({})
        };

        return fetch(this.baseUrl + '/get', requestOptions).then(res => res.json());
    }

    saveProfile = (email: string, password: string, passwordRepeat: string) => {
        const requestOptions = {
            ...this.authRequestOptions(),
            body: JSON.stringify({email, password, passwordRepeat})
        };

        return fetch(this.baseUrl + '/save', requestOptions).then(res => res.json());
    }

    sendFeedback = (subject: string, body: string) => {
        const requestOptions = {
            ...this.authRequestOptions(),
            body: JSON.stringify({subject, body})
        };

        return fetch(this.baseUrl + '/sendFeedback', requestOptions).then(res => res.json());
    }


    getPayments = () => {
        const requestOptions = {
            ...this.authRequestOptions(),
            body: JSON.stringify({})
        };

        return fetch(this.baseUrl + '/payments', requestOptions).then(res => res.json());
    }

    updatePassword = (password: string) => {
        const requestOptions = {
            ...this.authRequestOptions(),
            body: JSON.stringify({password})
        };

        return fetch(this.baseUrl + '/updatePassword', requestOptions).then(res => res.json());
    }

    processPayment = (paymentid: string, paytype: string) => {
        const requestOptions = {
            ...this.authRequestOptions(),
            body: JSON.stringify({paymentid, paytype})
        };

        return fetch(this.baseUrl + '/processPayment', requestOptions).then(res => res.json());
    }

    processPaymentSubscription = (paymentid: string, paytype: string) => {
        const requestOptions = {
            ...this.authRequestOptions(),
            body: JSON.stringify({paymentid, paytype})
        };

        return fetch(this.baseUrl + '/processPaymentSubscription', requestOptions).then(res => res.json());
    }

    changePaymentMethod = (paymentid: string) => {
        const requestOptions = {
            ...this.authRequestOptions(),
            body: JSON.stringify({paymentid})
        };

        return fetch(this.baseUrl + '/changePaymentMethod', requestOptions).then(res => res.json());
    }

    cancelSubscription = (id: number) => {
        const requestOptions = {
            ...this.authRequestOptions(),
            body: JSON.stringify({id})
        };

        return fetch(this.baseUrl + '/cancelSubscription', requestOptions).then(res => res.json());
    }

}

const ApiProfile = new ApiProfileService();
export default ApiProfile;

import React, {Fragment, useEffect, useState} from "react";

const help = [
    {
        title: 'Capibox Integration when Facebook Pixel installed on your website',
        url: 'https://docs.google.com/document/d/e/2PACX-1vQ1EsWBopqBnfcZnhERR7xHTf93VsGICIIyMFR60-_xWrURdb3puMq4R78bbFbruLzsDcVCsjP6JqyD/pub?embedded=true'
    },
    {
        title: 'Capibox Integration when you do NOT have a Facebook Pixel',
        url: 'https://docs.google.com/document/d/e/2PACX-1vTVt91rRVFRTgBR6hRdbPrVwo8wQ_ISBSVGDCnc4E5hQ07WfzRmkD9OCGW3cdxEUY2VeAQfpejZzJXj/pub?embedded=true'
    },
    {
        title: 'How to find Pixel ID',
        url: 'https://docs.google.com/document/d/e/2PACX-1vTK8qAiaVWT76ZtYAJI1BA9bF5mv7VfemvAD_8uhjPB30rFhLBQ-ciERWT6VDqrkePGkkKSA4EOjpKu/pub?embedded=true'
    },
    {
        title: 'How to find Access Token',
        url: 'https://docs.google.com/document/d/e/2PACX-1vSIZjQixOt3laPSZCFjfgypI-vlH2rO4OBOQykZlWeJvRgH6LO-lcLnkAsUi2fXLeNrNObfuSsxjQPC/pub?embedded=true'
    },
    {
        title: 'How to find Test Event Code',
        url: 'https://docs.google.com/document/d/e/2PACX-1vShw_AOYNESnIojgps0ygCmKQF6NFhnkNctCL1yKGLqqzN6WfFlRBW2YAuBEpVWeKWBuYJyy3LNd5ob/pub?embedded=true'
    },
    {
        title: 'How to connect Webhook',
        url: 'https://docs.google.com/document/d/e/2PACX-1vT7eVTrPPMCKv8-ySx84Fdwv3gKNTg1Az3w0ZT5umzz9et-rJVsvkjH41Ohlrwh9IU3DATzTqwbmQh1/pub?embedded=true'
    },
    {
        title: 'How to install Script',
        url: 'https://docs.google.com/document/d/e/2PACX-1vTcRkq7YCO0zfMBuVk3l9_fAA8r1q-y2tlumHUpPBsA4ZYUEOlUk8BHAvi4Q4P4j5P2DhFUHXBQammK/pub?embedded=true'
    },
]

export default function UserGuideDf() {
    const [activeSection, setActiveSection] = useState(-1);

    useEffect(() => {
        if (!!window.location.hash) {
            let hash = window.location.hash.split("#section-");
            if (hash.length === 2) {
                setActiveSection(parseInt(hash[1], 10));
            }
        }
    }, []);

    return (
        <div className={"container p-10"}>
            <div className={"bg-white px-10 py-5 shadow-md rounded-md"}>
                <div className={"space-y-8 container"}>
                    <p className={"text-xl"}>User guide</p>
                    <div className={"space-y-2 user-guide"}>
                        {help.map((item, index) => {
                            return (
                                <div key={"section-" + index} id={"section-" + index}>
                                    <a href={"#section-" + index}
                                       className={(index === activeSection ? "active" : "") + " font-semibold underline hover:no-underline"}
                                       onClick={() => setActiveSection(index === activeSection ? -1 : index)}>{item.title}</a>

                                    {index === activeSection &&
                                    <div>
                                        <iframe
                                            src={item.url}>
                                        </iframe>
                                    </div>
                                    }
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

import React, {Fragment} from "react";
import {IDataInvoice, IProfileData} from "./PaymentsPage";

interface Props {
    data: IProfileData,
}

export default function PaymentInvoices(props: Props) {
    const {data} = props;

    return (
        <Fragment>
            <p className={"text-xl"}>Invoices</p>
            <div className={"grid gap-2"} style={{zIndex: 10}}>
                <table className={"table"}>
                    <thead>
                    <tr>
                        <th>Date</th>
                        <th>Invoice no</th>
                        <th className={"text-right"}>Amount</th>
                        <th>Payment status</th>
                    </tr>
                    </thead>
                    <tbody>
                    {data.invoices.map((invoice: IDataInvoice, invoiceIndex: number) => {
                        return (
                            <tr key={'invoice-' + invoice.id}>
                                <td>{invoice.date}</td>
                                <td><a href={invoice.pdf} className={"underline hover:no-underline"}
                                       target={"_blank"}>{invoice.number}</a>
                                </td>
                                <td className={"text-right"}>{invoice.amount.toFixed(2)}</td>
                                <td>{invoice.status}</td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>
        </Fragment>
    )
}
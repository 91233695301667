import React, {Fragment} from 'react';
// import './assets/styles/screen.css';
// import './assets/styles/custom.css';
// import './assets/scss/base.scss'
import './assets/scss/v2.scss'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import LoginPage from "./pages/auth/LoginPage";
import SignupPage from "./pages/auth/SignupPage";
import {UserProvider} from "./Components/User/UserProvider";
import CampaignsListPage from "./pages/campaigns/CampaignsListPage";
import CampaignPage from "./pages/campaigns/CampaignPage";

import {Slide, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import UserGuide from "./pages/user-guides/UserGuide";
import SendFeedbackPage from "./pages/SendFeedbackPage";
import UsersListPage from "./pages/admin/UsersListPage";
import UserPage from "./pages/admin/UserPage";
import PaymentPage from "./pages/payments/PaymentPage";
import PaymentsPage from "./pages/payments/PaymentsPage";
import ResetPasswordPage from "./pages/auth/ResetPasswordPage";
import UserGuideCf from "./pages/user-guides/UserGuideCf";
import UserGuideDf from "./pages/user-guides/UserGuideDf";
import UserGuideTypeform from "./pages/user-guides/UserGuideTypeform";
import UserGuideFunnelair from "./pages/user-guides/UserGuideFunnelair";
import UserGuideGumroad from "./pages/user-guides/UserGuideGumroad";
import CampaignStatsPage from "./pages/campaigns/CampaignStatsPage";
import UserGuideSysteme from "./pages/user-guides/UserGuideSysteme";
import UserGuideWix from "./pages/user-guides/UserGuideWix";
import UrlShortenerListPage from "./pages/url-shortener/UrlShortenerListPage";
import UrlShortenerPage from "./pages/url-shortener/UrlShortenerPage";
import SignupSubscriptionPage from "./pages/auth/SignupSubscriptionPage";

function App() {
    return (
        <Fragment>
            <Router>
                <Switch>
                    <Route path={"/login"}><LoginPage/></Route>
                    <Route path={"/signup-old"}><SignupPage/></Route>
                    <Route path={"/signup"}><SignupSubscriptionPage/></Route>
                    <Route path={"/password-reset"}><ResetPasswordPage/></Route>
                    <UserProvider>

                        <Route path={"/user-guide"}><UserGuide/></Route>

                        <Route path={"/user-guide-cf"}><UserGuideCf/></Route>
                        <Route path={"/user-guide-df"}><UserGuideDf/></Route>
                        <Route path={"/user-guide-typeform"}><UserGuideTypeform/></Route>
                        <Route path={"/user-guide-funnelair"}><UserGuideFunnelair/></Route>
                        <Route path={"/user-guide-gumroad"}><UserGuideGumroad/></Route>
                        <Route path={"/user-guide-systemeio"}><UserGuideSysteme/></Route>
                        <Route path={"/user-guide-wix"}><UserGuideWix/></Route>

                        <Route path={"/funnel-stats/:id"}><CampaignStatsPage/></Route>
                        <Route path={"/funnels/:id"}><CampaignPage/></Route>
                        <Route path={"/funnels"} exact={true}><CampaignsListPage/></Route>
                        <Route path={"/successful-registration"} exact={true}><CampaignsListPage/></Route>

                        <Route path={"/send-feedback"} exact={true}><SendFeedbackPage/></Route>

                        <Route path={"/payments"} exact={true}><PaymentsPage/></Route>
                        <Route path={"/subscribe"} exact={true}><PaymentPage isValidSubscription={false}/></Route>

                        <Route path={"/url-shorteners"}><UrlShortenerListPage/></Route>
                        <Route path={"/url-shortener/:id"}><UrlShortenerPage/></Route>

                        <Route path={"/admin/users"} exact={true}><UsersListPage/></Route>
                        <Route path={"/admin/user/:id"} exact={true}><UserPage/></Route>
                    </UserProvider>

                </Switch>
            </Router>
            <ToastContainer hideProgressBar transition={Slide} autoClose={2000}/>
        </Fragment>
    );
}

export default App;

import React, {Fragment} from "react";
import CfLogo from "../../assets/images/external/ClickFunnels-300-150.png"
import DfLogo from "../../assets/images/external/DropFunnels-300-150.png"
import FunnelAirLogo from "../../assets/images/external/Funnelair-300-150.png"
import GumRoadLogo from "../../assets/images/external/gumroad-300-150.png"
import TypeformLogo from "../../assets/images/external/Typeform-300-150.png"
import SystemeLogo from "../../assets/images/external/systeme.png"
import WixLogo from "../../assets/images/external/WixLogo.png"

import {useHistory} from "react-router-dom";

export default function UserGuide() {
    const history = useHistory();

    const selectPlatform = (e: any, type: string) => {
        e.preventDefault();

        history.push('/user-guide-' + type);
    }

    const linkClass = "border border-gray-200 hover:border-gray-400 p-5 rounded-md flex-grow flex justify-center";

    return (
        <Fragment>
            <div className={"container p-10"}>
                <div className={"bg-white px-10 py-5 shadow-md rounded-md"}>
                    <div className={"space-y-8 container"}>
                        <p className={"text-xl"}>User guide - select platform</p>
                        <div className={"flex flex-wrap gap-3"}>
                            <a href={"/"} onClick={e => selectPlatform(e, 'wix')} className={linkClass}>
                                <img alt={"Wix"} src={WixLogo} style={{maxHeight: 120}}/>
                            </a>
                            <a href={"/"} onClick={e => selectPlatform(e, 'cf')} className={linkClass}>
                                <img alt={"ClickFunnels"} src={CfLogo} style={{maxHeight: 120}}/>
                            </a>
                            <a href={"/"} onClick={e => selectPlatform(e, 'df')} className={linkClass}>
                                <img alt={"DropFunnels"} src={DfLogo} style={{maxHeight: 120}}/>
                            </a>
                            <a href={"/"} onClick={e => selectPlatform(e, 'funnelair')} className={linkClass}>
                                <img alt={"Funnelair"}  src={FunnelAirLogo} style={{maxHeight: 120}}/>
                            </a>
                            <a href={"/"} onClick={e => selectPlatform(e, 'typeform')} className={linkClass}>
                                <img alt={"Typeform"} src={TypeformLogo} style={{maxHeight: 120}}/>
                            </a>
                            <a href={"/"} onClick={e => selectPlatform(e, 'gumroad')} className={linkClass}>
                                <img alt={"Gumroad"} src={GumRoadLogo} style={{maxHeight: 120}}/>
                            </a>
                            <a href={"/"} onClick={e => selectPlatform(e, 'systemeio')} className={linkClass}>
                                <img alt={"Systeme.io"} src={SystemeLogo} style={{maxHeight: 120}}/>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

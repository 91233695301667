import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {UrlShortener} from "../UrlShortenerListPage";
import {faExternalLink} from "@fortawesome/pro-solid-svg-icons";

interface Props {
    item: UrlShortener
}

export default function BtnRedirect(props: Props) {
    const redirectUrl = 'https://cpbx.me/' + props.item.shortUrl;

    return (
        <a href={redirectUrl} target={"_blank"}>
            <FontAwesomeIcon icon={faExternalLink}/>
        </a>
    )
}

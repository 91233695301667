import React, {useEffect} from "react";
import {useHistory, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import ApiRest from "../../service/ApiRest";
import {UrlShortener} from "./UrlShortenerListPage";

interface ParamTypes {
    id: string
}

export default function UrlShortenerPage() {
    const history = useHistory();

    const {id} = useParams<ParamTypes>();

    const [title, onTitleChange] = React.useState('');
    const [sourceUrl, onSourceUrlChange] = React.useState('');
    const [shortUrl, onShortUrlChange] = React.useState('');

    const [utmSource, onUtmSourceChange] = React.useState('');
    const [utmMedium, onUtmMediumChange] = React.useState('');
    const [utmCampaign, onUtmCampaignChange] = React.useState('');
    const [adContent, onAdContentChange] = React.useState('');

    const [sourceUrlB, onSourceUrlBChange] = React.useState('');
    const [urlRatioA, onUrlRatioAChange] = React.useState(50);

    const goBack = (e: React.MouseEvent) => {
        e.preventDefault();
        history.push('/url-shorteners');
    }

    const doSave = (e: React.MouseEvent) => {
        e.preventDefault();

        if (!title) {
            toast.error('Please enter Title');
            return;
        }
        if (!sourceUrl) {
            toast.error('Please enter Source URL');
            return;
        }
        ApiRest.updateElement('url-shortener', parseInt(id, 10), {
            title,
            sourceUrl,
            utmSource,
            utmMedium,
            utmCampaign,
            adContent,
            sourceUrlB,
            urlRatioA
        }).then((res) => {
            toast.success('Activated');
            history.push('/url-shorteners');
        })
    }
    const getData = () => {
        ApiRest.getElement('url-shortener', parseInt(id, 10)).then((res: UrlShortener) => {
            onTitleChange(res.title);
            onSourceUrlChange(res.sourceUrl);
            onUtmSourceChange(res.utmSource);
            onUtmMediumChange(res.utmMedium);
            onUtmCampaignChange(res.utmCampaign);
            onSourceUrlBChange(res.sourceUrlB);
            onUrlRatioAChange(res.urlRatioA);
            onShortUrlChange(res.shortUrl);
            onAdContentChange(res.adContent);
        });
    }
    useEffect(getData, [id]);

    return (
        <div className={"data-container p-10"}>
            <div className={"bg-white px-10 py-5 shadow-md rounded-md"}>
                <div className={"space-y-8 container"}>
                    <div className="space-y-4">
                        <div className={"flex"}>
                            <label className={"w-72"}>Title</label>
                            <span className={"flex-grow"}><input type="text" placeholder="" value={title}
                                                                 onChange={e => onTitleChange(e.target.value)}/></span>
                        </div>
                        <div className={"flex"}>
                            <label className={"w-72"}>Source URL</label>
                            <span className={"flex-grow"}><input type="text" placeholder="" value={sourceUrl}
                                                                 onChange={e => onSourceUrlChange(e.target.value)}/></span>
                        </div>
                        <div className={"flex"}>
                            <label className={"w-72"}>Back-half link</label>
                            <p>https://cpbx.me/<strong>{shortUrl}</strong></p>
                        </div>
                        <div className={"pt-4 pb-2"}>
                            <p className={"text-xl"}>
                                UTM Parameters
                            </p>
                            <p>UTMs can help you track web traffic in analytics tools</p>
                        </div>
                        <div className={"flex"}>
                            <label className={"w-72"}>Source</label>
                            <span className={"flex-grow"}><input type="text" placeholder="" value={utmSource}
                                                                 onChange={e => onUtmSourceChange(e.target.value)}/></span>
                        </div>
                        <div className={"flex"}>
                            <label className={"w-72"}>Medium</label>
                            <span className={"flex-grow"}><input type="text" placeholder="" value={utmMedium}
                                                                 onChange={e => onUtmMediumChange(e.target.value)}/></span>
                        </div>
                        <div className={"flex"}>
                            <label className={"w-72"}>Campaign</label>
                            <span className={"flex-grow"}><input type="text" placeholder="" value={utmCampaign}
                                                                 onChange={e => onUtmCampaignChange(e.target.value)}/></span>
                        </div>
                        <div className={"flex"}>
                            <label className={"w-72"}>Ad content</label>
                            <span className={"flex-grow"}><input type="text" placeholder="" value={adContent}
                                                                 onChange={e => onAdContentChange(e.target.value)}/></span>
                        </div>
                        <div className={"pt-4 pb-2"}>
                            <p className={"text-xl"}>
                                Test A/B link rotator
                            </p>
                            <p>Set alternate redirect URLs. Thanks to this, the redirection of links will work according
                                to your settings.</p>
                        </div>
                        <div className={"flex"}>
                            <label className={"w-72"}>B Link variation URL</label>
                            <span className={"flex-grow"}><input type="text" placeholder="" value={sourceUrlB}
                                                                 onChange={e => onSourceUrlBChange(e.target.value)}/></span>
                        </div>
                        <div className={"flex"}>
                            <label className={"w-72"}>Adjust A/B values</label>
                            <span className={"flex-grow"}>
                                <select value={urlRatioA}
                                        onChange={e => onUrlRatioAChange(parseInt(e.target.value, 10))}>
                                    <option value={10}>A Link 10% - B Link 90%</option>
                                    <option value={20}>A Link 20% - B Link 80%</option>
                                    <option value={30}>A Link 30% - B Link 70%</option>
                                    <option value={40}>A Link 40% - B Link 60%</option>
                                    <option value={50}>A Link 50% - B Link 50%</option>
                                    <option value={60}>A Link 60% - B Link 40%</option>
                                    <option value={70}>A Link 70% - B Link 30%</option>
                                    <option value={80}>A Link 80% - B Link 20%</option>
                                    <option value={90}>A Link 90% - B Link 10%</option>
                                </select>
                            </span>
                        </div>
                    </div>
                    <p className="flex justify-end gap-2">
                        <button className={"cancel-btn"} onClick={goBack}>Cancel</button>
                        <button className={"main-btn"} onClick={doSave}>{"Activate"}</button>
                    </p>
                </div>
            </div>
        </div>
    )
}

import React from "react";
import AppHeader from "./header/AppHeader";

interface Props {
    children: React.ReactChild,
}

export default function AppLayout({children}: Props) {
    return (
        <div className={"flex w-screen"}>
            <AppHeader/>
            <div className={"data-container"}>
                {children}
            </div>
        </div>
    )
}

import React, {Fragment, useState} from "react";
import {IProfileData} from "../PaymentsPage";
import {faPencilAlt} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {toast} from "react-toastify";
import ApiProfile from "../../../service/ApiProfile";

interface Props {
    data: IProfileData
}

export default function AccountPage(props: Props) {
    const {data} = props;
    const [showForm, setShowForm] = useState(false);

    const [password, setPassword] = useState('');
    const [passwordRepeat, setPasswordRepeat] = useState('');

    const updatePassword = () => {
        if (!password || !passwordRepeat) {
            toast.error('Fill password fields');
            return;
        }
        if (password !== passwordRepeat) {
            toast.error('Passwords don\t match');
            return;
        }
        ApiProfile.updatePassword(password).then(() => {
            toast.success('Password has been changed');
            setPassword('');
            setPasswordRepeat('');
            setShowForm(false);
        })
    }

    return (
        <Fragment>
            <p className={"text-xl"}>Account login </p>
            <div className={"grid gap-2"}>
                <div className={"flex"}>
                    <label className={"w-40"}>Email:</label>
                    <p>{data.email}</p>
                </div>
                {!showForm &&
                <div className={"flex"}>
                    <label className={"w-40"}>Password:</label>
                    <p>********
                        <button className={"ml-2"} onClick={() => setShowForm(true)}>
                            <FontAwesomeIcon icon={faPencilAlt} size={"sm"}/></button>
                    </p>
                </div>
                }

                {showForm && <Fragment>
                    <div className={"flex"}>
                        <label className={"w-40"}>Password:</label>
                        <span className={"flex-grow"}>
                            <input type="password" placeholder=""
                                   value={password}
                                   onChange={(e) => setPassword(e.target.value)}
                            />
                        </span>
                    </div>
                    <div className={"flex"}>
                        <label className={"w-40"}>Repeat password:</label>
                        <span className={"flex-grow"}>
                            <input type="password" placeholder=""
                                   value={passwordRepeat}
                                   onChange={(e) => setPasswordRepeat(e.target.value)}
                            />
                        </span>
                    </div>
                    <p className={"flex"}>
                        <button onClick={updatePassword} className="main-btn">
                            Update
                        </button>
                    </p>
                </Fragment>}

            </div>
        </Fragment>
    )
}

import React from "react";

import {useHistory} from 'react-router-dom';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencil} from "@fortawesome/pro-regular-svg-icons";
import {UrlShortener} from "../UrlShortenerListPage";

interface Props {
    item: UrlShortener
}

export default function BtnEdit(props: Props) {
    const link = '/url-shortener/' + props.item.id;
    const history = useHistory();
    const goToLink = (e: React.MouseEvent) => {
        e.preventDefault();
        history.push(link);
    }

    return (
        <a href={link} onClick={goToLink}>
            <FontAwesomeIcon icon={faPencil}/>
        </a>
    )
}

import React, {useEffect, useState} from "react";
import {useHistory, useLocation} from 'react-router-dom';
import {NaeApiAuth} from "nae-react-auth";
import {
    faBell,
    faCommentAlt,
    faLink,
    faQuestionCircle,
    faSignOut,
    faUserCircle,
    faUsers
} from "@fortawesome/pro-light-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useUser} from "../../../Components/User/UserProvider";

export default function AppMenu() {
    const history = useHistory();
    let location = useLocation();
    const [activeUrl, setActiveUrl] = useState('');
    const {user} = useUser();

    useEffect(() => {
        setActiveUrl(trimByChar(location.pathname, '/'))
    }, [location.pathname]);


    const trimByChar = (string: string, charToRemove: string) => {
        while (string.charAt(0) === charToRemove) {
            string = string.substring(1);
        }

        while (string.charAt(string.length - 1) === charToRemove) {
            string = string.substring(0, string.length - 1);
        }

        return string
    }

    const goToNotifications = (e: React.MouseEvent) => {
        e.preventDefault();
        history.push('/funnels');
    }
    const goToAccountSettings = (e: React.MouseEvent) => {
        e.preventDefault();
        history.push('/account-settings');
    }
    const goToUserGuide = (e: React.MouseEvent) => {
        e.preventDefault();
        history.push('/user-guide');
    }
    const goToPayments = (e: React.MouseEvent) => {
        e.preventDefault();
        history.push('/payments');
    }

    const goToFeedback = (e: React.MouseEvent) => {
        e.preventDefault();
        history.push('/send-feedback');
    }

    const goToUrlShortener = (e: React.MouseEvent) => {
        e.preventDefault();
        history.push('/url-shorteners');
    }

    const isNotifications = activeUrl === '' || activeUrl.indexOf('funnels') === 0;

    const doLogout = (e: any) => {
        e.preventDefault();
        NaeApiAuth.doLogout().then(() => {
            history.push('/login');
        })
    }

    const defClass = "text-white font-bold pt-4 pb-4 pl-4 ";
    const svgClass = "mr-2";

    return (
        <div className={"grid gap-2 text-white capibox-menu"}>

            <a href="/" onClick={goToNotifications} className={defClass + (isNotifications ? "active" : "")}>
                <FontAwesomeIcon className={svgClass} fixedWidth={true} icon={faBell} size={"lg"}/> Funnels
            </a>


            <a href="/url-shorteners" onClick={goToUrlShortener}
               className={defClass + (activeUrl === 'url-shorteners' ? "active" : "")}>
                <FontAwesomeIcon className={svgClass} fixedWidth={true} icon={faLink} size={"lg"}/> URL Shortener
            </a>


            <a href="/user-guide" className={defClass + (activeUrl === 'user-guide' ? "active" : "")}
               onClick={goToUserGuide}>
                <FontAwesomeIcon className={svgClass} fixedWidth={true} icon={faQuestionCircle} size={"lg"}/>
                User Guide
            </a>


            <a href="/send-feedback" className={defClass + (activeUrl === 'send-feedback' ? "active" : "")}
               onClick={goToFeedback}>
                <FontAwesomeIcon className={svgClass} fixedWidth={true} icon={faCommentAlt} size={"lg"}/>
                Send feedback
            </a>

            <a href="/payments" className={defClass + (activeUrl === 'payments' ? "active" : "")}
               onClick={goToPayments}>
                <FontAwesomeIcon className={svgClass} fixedWidth={true} icon={faUserCircle} size={"lg"}/>
                Account page
            </a>

            <a href={"https://www.facebook.com/groups/capibox/"} className={defClass}>
                <FontAwesomeIcon className={svgClass} fixedWidth={true} icon={faUsers} size={"lg"}/>
                Join Capibox Community
            </a>

            <a href="/logout" className={defClass} onClick={doLogout}>
                <FontAwesomeIcon className={svgClass} fixedWidth={true} icon={faSignOut} size={"lg"}/>
                Sign out
            </a>

        </div>
    )
}

import React, {Fragment, useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import ApiRest from "../../service/ApiRest";
import {Funnel} from "../campaigns/CampaignsListPage";
import {User} from "./UsersListPage";
import ApiUser from "../../service/ApiUser";
import {toast} from "react-toastify";
import UserCampaignComponent from "./UserCampaignComponent";

interface ParamTypes {
    id: string
}

export default function UserPage() {
    const history = useHistory();

    const [trialTill, setTrialTill] = useState('');

    const [user, setUser] = useState<User | null>(null);

    const {id} = useParams<ParamTypes>();

    const getData = () => {

        ApiRest.getElement('user', parseInt(id, 10)).then(res => {
            setUser(res);
            setTrialTill(res.trialTill);
        });

    }
    useEffect(getData, [id]);

    if (!user) {
        return <Fragment/>
    }

    const refresh = () => {
        window.location.reload();
    }

    const doSave = (e: any) => {
        e.preventDefault();
        ApiUser.updateElement(user.id, {trialTill}).then((res) => {
            toast.success('Success');
            refresh();
        })
    }

    return (
        <div className={"data-container p-10"}>
            <div className={"bg-white px-10 py-5 shadow-md rounded-md"}>
                <div className={"grid gap-4"}>
                    {user && <Fragment>
                        <p className={"text-xl"}>User ({user.email}) - Details</p>
                        <div className={"grid gap-2"}>
                            <p className={"flex"}>
                                <label className={"w-72"}>Trial till</label>
                                <span className={"flex-grow"}>
                                <input type="text" placeholder=""
                                       value={trialTill}
                                       onChange={(e: any) => setTrialTill(e.target.value)}
                                />
                            </span>
                            </p>
                            <p className={"flex"}>
                                <label className={"w-72"}>Valid till</label>
                                {user.validTill}
                            </p>
                            <p className={"flex"}>
                                <label className={"w-72"}>Is valid</label>
                                {user.isValid ? "Yes" : "No"}
                            </p>
                            <div className="flex gap-4">
                                <button className={"cancel-btn"} onClick={refresh}>Cancel</button>
                                <button className={"main-btn"} onClick={doSave}>{"Save"}</button>
                            </div>
                        </div>
                    </Fragment>}
                    {user && user.campaigns.map((element: Funnel) =>
                        <UserCampaignComponent user={user} element={element}/>
                    )}
                </div>
            </div>
        </div>
    )
}

import React, {Fragment, useCallback, useState} from "react";
import {CardElement, useElements, useStripe} from '@stripe/react-stripe-js';
import ApiProfile from "../../service/ApiProfile";
import {useHistory} from 'react-router-dom'

interface Props {
    mode: number,
}

export default function PaymentCheckoutBtn(props: Props) {
    const history = useHistory();
    const {mode} = props;
    const stripe = useStripe();
    const elements = useElements();
    const [error, setError] = useState('');
    const [processing, setProcessing] = useState(false);

    const doCheckout = useCallback((e: any) => {
        e.preventDefault();
        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }

        // Get a reference to a mounted CardElement. Elements knows how
        // to find your CardElement because there can only ever be one of
        // each type of element.
        const cardElement = elements.getElement(CardElement);

        if (!cardElement) {
            return;
        }

        setProcessing(true);

        stripe
            .createPaymentMethod({
                type: 'card',
                card: cardElement,
            })
            .then((result) => {

                if (result && result.error) {
                    setError(!!result.error.message ? result.error.message : '');
                    setProcessing(false);
                } else if (result && result.paymentMethod) {
                    setError('');
                    ApiProfile.processPayment(result.paymentMethod.id, mode === 0 ? 'year' : 'month').then(res => {
                        if (res.success === 1) {
                            // window.location.reload();
                            history.push('/payments');
                        } else if (res.error) {
                            setError(res.error.description);
                            setProcessing(false);
                        } else {
                            setError('Error, please contact support');
                            setProcessing(false);
                        }
                    })
                } else {
                    setError('Error, please contact support');
                    setProcessing(false);
                }
            });
    }, [stripe, elements, mode]);


    return (
        <Fragment>
            {mode === -1 && <div className={"text-pink-800 text-center"}>{"Choose payment period"}</div>}
            {error && <div className={"text-pink-800"}>{error}</div>}
            {mode > -1 &&
            <button onClick={doCheckout} className="main-btn">
                {processing ? "Confirming payment..." :
                    <Fragment>Subscribe to {mode === 0 ? 'annual' : 'monthly'}</Fragment>}
            </button>
            }
        </Fragment>
    )
}

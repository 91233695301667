import React, {Fragment} from "react";
import {IDataSubscription, IProfileData} from "./PaymentsPage";
import ApiProfile from "../../service/ApiProfile";
import {toast} from "react-toastify";

interface Props {
    data: IProfileData,
    getData: () => void,
}


export default function PaymentCancelAccount(props: Props) {
    const {data, getData} = props;

    const cancelSubscription = (id: number) => {
        ApiProfile.cancelSubscription(id).then((res) => {
            if (res.success === 1) {
                toast.success("Cancellation request sent");
            } else {
                toast.error("Error, please contact support");
            }
            getData();
        })
    }

    return (
        <Fragment>
            {data.subscriptions.map((item: IDataSubscription) => {
                return (
                    <div key={"cancel-" + item.id}>
                        {(item.status === 'active' || item.status === 'trialing') && !item.cancel_at_period_end ?
                            <button className={"cancel-btn"} onClick={() => cancelSubscription(item.id)}>Request to cancel account</button> : ''}
                    </div>
                )
            })}
        </Fragment>
    )
}

import React, {Fragment, useState} from "react";
import {IDataCard, IProfileData} from "./PaymentsPage";
import {CardElement, Elements} from "@stripe/react-stripe-js";
import UpdateCardBtn from "./UpdateCardBtn";
import {loadStripe} from "@stripe/stripe-js";
import {BackendConfig} from "../../config/BackendConfig";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencilAlt} from "@fortawesome/pro-solid-svg-icons";

interface Props {
    data: IProfileData
}

const stripePromise = loadStripe(BackendConfig.stripePkKey);

export default function PaymentCardPage(props: Props) {
    const {data} = props;
    const [showCardUpdate, setShowCardUpdate] = useState(false);
    return (
        <Fragment>
            <p className={"text-xl"}>Payment card</p>
            <div className={"grid gap-2"}>
                {data.cards.map((card: IDataCard, index: number) => {
                    return (
                        <div key={"card-" + index}>
                            <span style={{textTransform: 'capitalize'}}>{card.brand}</span> ending {card.last4}<br/>
                            Expires on {card.exp_month} / {card.exp_year}
                            <button className={"ml-2"} onClick={() => setShowCardUpdate(true)}>
                                <FontAwesomeIcon icon={faPencilAlt} size={"sm"}/>
                            </button>
                        </div>
                    )
                })}
            </div>
            {showCardUpdate &&
            <Fragment>
                <hr/>
                <p className={"text-lg"}>Update payment method</p>

                <Elements stripe={stripePromise}>

                    <div className={"grid gap-2 w-1/2"}>
                        <CardElement
                            options={{
                                style: {
                                    base: {
                                        fontSize: '16px',
                                        color: '#32325d',
                                        '::placeholder': {
                                            color: '#aab7c4',
                                        },
                                    },
                                    invalid: {
                                        color: '#fa755a',
                                        iconColor: '#fa755a',
                                    },
                                },
                            }}
                        />

                        <p className={"flex mt-2"}>
                            <UpdateCardBtn/>
                        </p>
                    </div>

                </Elements>
            </Fragment>
            }


        </Fragment>
    )
}
import React, {Fragment, useState} from "react";
import {User} from "./UsersListPage";
import {Funnel} from "../campaigns/CampaignsListPage";
import ApiRest from "../../service/ApiRest";

interface Props {
    user: User,
    element: Funnel
}

const fields = [
    'createdAt',
    'email',
    'sent',
    'status',
    'total',
    'type',
    'fbc',
    'fbp',
    'ip',
    'ref',
    'firstName',
    'lastName',
    'phone',
    'city',
    'countryCode',
    'zipCode',
    'state'
]

export default function UserCampaignComponent({user, element}: Props) {
    const [contactData, setContactData] = useState([]);
    const [email, setEmail] = useState('');

    const getData = (e: any) => {
        e.preventDefault();
        ApiRest.getElementRel('campaign', element.id, 'contactData', {email}).then(res => {
            setContactData(res);
        })
    }
    return (
        <Fragment>
            <p className={"text-xl"}>User ({user.email})</p>
            <div className={"grid gap-2"}>
                <div className={"flex"}>
                    <label className={"w-72"}>Name</label>
                    <span className={"flex-grow"}>
                    <input type="text" placeholder=""
                           value={element.name}
                           readOnly={true}
                    />
                        </span>
                </div>
                <div className={"flex"}>
                    <label className={"w-72"}>Platform</label>
                    {element.platform}
                </div>

                <div className={"flex"}>
                    <label className={"w-72"}>Last activity</label>
                    {element.lastActivity}
                </div>

                <p className={"flex"}>
                    <label className={"w-72"}>Facebook Pixel ID</label>
                    <span className={"flex-grow"}>
                    <input type="text" placeholder=""
                           value={element.pixelId}
                           readOnly={true}/>
                    </span>
                </p>

                <div className={"flex"}>
                    <label className={"w-72"}>Access Token</label>
                    <span className={"flex-grow"}>
                    <input type="text" placeholder=""
                           value={element.accessToken}
                           readOnly={true}
                    />
                    </span>
                </div>
                <div className={"flex"}>
                    <label className={"w-72"}>Test event</label>
                    <span className={"flex-grow"}>
                    <input type="text" placeholder=""
                           value={element.testEventCode}
                           readOnly={true}
                           style={{marginRight: 20}}
                    />
                    </span>
                </div>

                <div className={"flex"}>
                    <label className={"w-72"}>Url</label>
                    {element.url}
                </div>
            </div>

            <div className={"flex"}>
                <span className={"flex-grow"}>
                    <input value={email} onChange={e => setEmail(e.target.value)}/>
                </span>
                <span className={"w-72 ml-3"}>
                    <button className={"main-btn"} onClick={getData}>search</button>
                </span>
            </div>
            <div style={{overflow: 'auto'}}>
                <table style={{zIndex: 10}}>
                    <thead>
                    <tr>
                        {fields.map(f => (<th key={"th-" + f}>{f}</th>))}
                    </tr>
                    </thead>
                    <tbody>
                    {contactData.map((el) => {
                        return (
                            <tr>
                                {fields.map(f => (<td key={"td-" + f}>{el[f]}</td>))}
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>
        </Fragment>
    )
}

import React, {useEffect, useState} from "react";

import {useHistory} from 'react-router-dom'
import Field from "../../form/Field";
import LogoBlack from "../../assets/images/logo_black.png";
import {NaeApiAuth} from "nae-react-auth";
import {toast} from "react-toastify";
import AuthMobileAlert from "./AuthMobileAlert";


export default function ResetPasswordPage() {
    const [email, setEmail] = useState('');

    const history = useHistory();

    const goToLogin = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        history.push('/login');
    }

    const addSignupClass = () => {
        document.getElementsByTagName('html')[0].classList.add('theme-signup');
    }
    useEffect(() => {
        addSignupClass()
    }, []);

    const doPasswordReset = () => {
        NaeApiAuth.doRemind(email).then(res => {
            if (res.isError) {
                toast.error(res.error.description)
                return
            }
            history.push('/login');
        })

    }

    return (
        <div className={"main-bg h-screen w-screen"}>
            <div className={"h-screen flex justify-center items-center"}>
                <div className={"w-96 px-8 py-10 bg-white shadow-md rounded-md grid gap-2"}>
                    <AuthMobileAlert/>
                    <div className={"text-center mb-4"}>
                        <img src={LogoBlack} width={150} className={"mx-auto mb-8"} alt={""}/>
                        <p className={"text-lg font-medium"}>Password reset!</p>

                    </div>

                    <Field title={"Enter email address"} fieldKey={"email"} val={email}
                           onChange={(key: string, val: string) => setEmail(val)}
                           required={true}
                           hideLabel={true}/>


                    <button type="button" className={"main-btn mt-4"} onClick={doPasswordReset}>Reset</button>

                    <footer>
                        <p className={"text-sm mt-4 text-center"}>or</p>
                        <p className={"text-center mt-4 text-xs"}>Already have an account? <a href="/login" className={"text-sm"} onClick={goToLogin}>Log in</a></p>
                    </footer>
                </div>

            </div>
        </div>
    )
}

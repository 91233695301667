import React, {Fragment} from "react";
import {IDataSubscription, IProfileData} from "./PaymentsPage";
import {useHistory} from "react-router-dom";

interface Props {
    data: IProfileData,
}

export default function PaymentsFreeTrial(props: Props) {
    const {data} = props;

    const history = useHistory();

    const doSubscribe = () => {
        // dispatch({type: 'SET_NEED_PAYMENT'});
        history.push('/subscribe')
    }

    const hasTrialing = !!data && (data.subscriptions.length > 0 && data.subscriptions.filter(f => f.status === 'trialing').length > 0);


    return (
        <Fragment>
            <p className={"text-xl"}>Capibox Free Trial</p>
            <div className={"grid gap-2"}>
                {hasTrialing?<Fragment>
                        {data.subscriptions.filter(f => f.status === 'trialing').map((item: IDataSubscription) => {
                            return (
                                <div key={"sub-" + item.id}>
                                    {item.status === 'trialing' && !item.cancel_at_period_end ? <Fragment>
                                        Your free trial will end and your plan will automatically renew on {item.endDate}. You will charged
                                        ${item.amount.toFixed(2)}.
                                    </Fragment> : <Fragment>
                                        Plan active till {item.endDate}
                                    </Fragment>}
                                </div>
                            )
                        })}
                </Fragment>:
                    <Fragment>
                        {data.valid ? <Fragment>
                            <p>
                                Your 14-day free trial lasts until midnight on {data.validTill}.<br/>
                                After that, you will need to upgrade to a paid plan to continue placing
                                orders.
                            </p>

                        </Fragment> : <Fragment>
                            <p>
                                Your 14-day free trial ended.
                            </p>
                        </Fragment>}
                        <p className={"flex"}>
                            <button className={"main-btn"} onClick={doSubscribe}>Subscribe</button>
                        </p>
                    </Fragment>}

            </div>
        </Fragment>
    )
}

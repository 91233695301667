import React, {Fragment, useEffect, useState} from "react";
import NewButton from "./components/NewButton";
import ApiRest from "../../service/ApiRest";
import BtnEdit from "./components/BtnEdit";
import BtnRemove from "./components/BtnRemove";
import {useHistory} from 'react-router-dom'
import BtnStats from "./components/BtnStats";

export interface Funnel {
    id: number,
    name: string,
    pixelId: string,
    accessToken: string,
    url: string,
    testEventCode: string,
    lastActivity: string,
    upsellSelection: number,
    platform: string,
}

export default function CampaignsListPage() {
    const history = useHistory();
    const [data, setData] = useState<Funnel[]>([]);
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const goToLink = (e: React.MouseEvent, id: number) => {
        e.preventDefault();
        history.push('/funnels/' + id);
    }

    const getData = () => {
        ApiRest.getElements('campaign').then((res) => {
            if (res) {
                setData(res);
                setIsDataLoaded(true);
            }
        })
    }

    useEffect(getData, []);

    if (!isDataLoaded && !data.length) {
        return <Fragment/>
    }

    return (
        <div className={"space-y-8 p-8"}>
            {
                data.length === 0 ?
                    <Fragment>
                        <div>
                            <p className={"text-xl mb-4"}>Connect your first funnel</p>
                            <NewButton/>
                            {/*<figure className="background">*/}
                            {/*    <img src={ContentBg} alt="" width="444" height="332"/>*/}
                            {/*</figure>*/}
                        </div>
                    </Fragment> :

                    <Fragment>

                        <NewButton/>

                        <table className={"table-auto"}>
                            <thead>
                            <tr>
                                <th>Funnel name</th>
                                <th>Last activity</th>
                                <th>Actions</th>
                            </tr>
                            </thead>
                            <tbody>
                            {data.map((item: Funnel) => {
                                return (
                                    <tr key={"i-" + item.id}>
                                        <td onClick={(e) => goToLink(e, item.id)}
                                            className="font-semibold cursor-pointer">{item.name}</td>
                                        <td onClick={(e) => goToLink(e, item.id)}
                                            className="font-semibold cursor-pointer">
                                            {item.lastActivity}
                                        </td>
                                        <td className={"w-40"}>
                                            <div className={"space-x-5"}>
                                                <BtnStats item={item}/>
                                                <BtnEdit item={item}/>
                                                <BtnRemove reload={getData} item={item}/>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>

                    </Fragment>
            }
        </div>
    )
}

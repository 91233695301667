class NaeApiAuthService {
    private baseUrl: string

    constructor() {
        this.baseUrl = '/app/auth'
        if (process.env.NODE_ENV === 'development') {
            this.baseUrl = 'http://localhost:8000/app/auth'
        }
    }

    plainRequestOptions = () => {
        return {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        }
    }

    doLogin = (email: string, password: string) => {
        const requestOptions = {
            ...this.plainRequestOptions(),
            body: JSON.stringify({email, password})
        }

        return fetch(this.baseUrl + '/login', requestOptions).then((res) =>
            res.json()
        )
    }

    doRegister = (email: string, password: string, passwordRepeat: string) => {
        const requestOptions = {
            ...this.plainRequestOptions(),
            body: JSON.stringify({
                email,
                password,
                passwordRepeat,
                fbc: this.getCookieByName('_fbc'),
                fbp: this.getCookieByName('_fbp'),
            })
        }

        return fetch(this.baseUrl + '/register', requestOptions).then((res) =>
            res.json()
        )
    }


    doRegisterSubscription = (email: string, password: string, passwordRepeat: string, paymentid: string, paytype: string) => {
        const requestOptions = {
            ...this.plainRequestOptions(),
            body: JSON.stringify({
                email,
                password,
                passwordRepeat,
                fbc: this.getCookieByName('_fbc'),
                fbp: this.getCookieByName('_fbp'),
                paymentid,
                paytype,
            })
        }

        return fetch(this.baseUrl + '/processPaymentSubscription', requestOptions).then((res) =>
            res.json()
        )
    }

    doRemind = (email: string) => {
        const requestOptions = {
            ...this.plainRequestOptions(),
            body: JSON.stringify({email})
        }

        return fetch(this.baseUrl + '/remind', requestOptions).then((res) =>
            res.json()
        )
    }

    doLogout = () => {
        return new Promise((resolve) => {
            window.localStorage.setItem('token', '')
            resolve('')
        })
    }

    getCookieByName = (name: string) => {
        const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
        if (match) {
            return match[2];
        } else {
            return '';
        }
    }
}

const NaeApiAuth = new NaeApiAuthService()
export default NaeApiAuth

import React from "react";

import {useHistory} from 'react-router-dom';
import {Funnel} from "../CampaignsListPage";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAnalytics, faPencil} from "@fortawesome/pro-regular-svg-icons";

interface Props {
    item: Funnel
}

export default function BtnStats(props: Props) {
    const link = '/funnel-stats/' + props.item.id;
    const history = useHistory();

    const goToStats = (e: React.MouseEvent) => {
        e.preventDefault();
        history.push(link);
    }

    return (
        <a href={link} onClick={goToStats}>
            <FontAwesomeIcon icon={faAnalytics}/>
        </a>
    )
}

import React, {Fragment, useState} from "react";
import {CardElement, useElements, useStripe} from '@stripe/react-stripe-js';
import ApiProfile from "../../service/ApiProfile";

interface Props {
}

export default function UpdateCardBtn(props: Props) {
    const stripe = useStripe();
    const elements = useElements();
    const [error, setError] = useState('');
    const [processing, setProcessing] = useState(false);

    const doCheckout = (e: any) => {
        e.preventDefault();
        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }

        // Get a reference to a mounted CardElement. Elements knows how
        // to find your CardElement because there can only ever be one of
        // each type of element.
        const cardElement = elements.getElement(CardElement);

        if (!cardElement) {
            return;
        }

        setProcessing(true);

        stripe
            .createPaymentMethod({
                type: 'card',
                card: cardElement,
            })
            .then((result: any) => {

                if (result && result.error) {
                    setProcessing(false);
                    setError(!!result.error.message ? result.error.message : '');
                } else if (result && result.paymentMethod) {
                    setError('');
                    ApiProfile.changePaymentMethod(result.paymentMethod.id).then(res => {
                        if (res.success === 1) {
                            window.location.reload();
                        } else if (res.error) {
                            setProcessing(false);
                            setError(res.error.description);
                        } else {
                            setProcessing(false);
                            setError('Error, please contact support');
                        }
                    })
                } else {
                    setProcessing(false);
                    setError('Error, please contact support');
                }
            });
    }


    return (
        <Fragment>
            {error && <div className={"text-danger"}>{error}</div>}
            <button onClick={doCheckout} className="main-btn">
                {processing ? "Confirming card..." : "Update"}
            </button>
        </Fragment>
    )
}

import React, {Fragment, useEffect, useState} from "react";
import ApiRest from "../../service/ApiRest";
import {useHistory, useParams} from "react-router-dom";

interface ParamTypes {
    id: string
}

interface IDataItem {
    time: string,
    total: number,
    currency: string,
    type: 'string'
}

interface IData {
    name: string,
    data: IDataItem[],
}

export default function CampaignStatsPage() {
    const {id} = useParams<ParamTypes>();

    const [data, setData] = useState<IData | null>(null);

    const getData = () => {
        ApiRest.getElementRel(
            'campaign',
            parseInt(id, 10),
            'userContactData',
            {}
        ).then(res => {
            // setContactData(res.data);
            setData(res);
        })
    }
    useEffect(getData, [id]);

    const history = useHistory();
    const goBack = () => {
        history.goBack();
    }

    return (
        <div className={"data-container p-10"}>
            <div>
                {!!data && <Fragment>
                    <div className={"space-y-8 container"}>
                        <p className={"text-xl"}>{data.name} last activity</p>

                        <table className={"table"} style={{zIndex: 10}}>
                            <thead>
                            <tr>
                                <th>Time</th>
                                <th>Type</th>
                                <th className={"text-right"}>Total</th>
                            </tr>
                            </thead>
                            <tbody>
                            {data.data.map((el: IDataItem, index: number) => {
                                return (
                                    <tr key={"stat-" + id + "-" + index}>
                                        <td>{el.time}</td>
                                        <td>{el.type}</td>
                                        <td className={"text-right"}>{el.total.toFixed(2)} {el.currency}</td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>

                        <button className={"cancel-btn"} onClick={goBack}>back</button>
                    </div>
                </Fragment>}
            </div>
        </div>
    )
}

import React from "react";
import {confirmAlert} from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import {Funnel} from "../CampaignsListPage";
import ApiRest from "../../../service/ApiRest"; // Import css
import {faTrashAlt} from "@fortawesome/pro-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface Props {
    item: Funnel,
    reload: () => void
}

export default function BtnRemove(props: Props) {
    const remove = (e: React.MouseEvent) => {
        e.preventDefault();

        confirmAlert({
            title: 'Confirm to submit',
            message: 'Are you sure to delete funnel "' + props.item.name + '"?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => ApiRest.deleteElement('campaign', props.item.id).then(() => {
                        props.reload();
                    })
                },
                {
                    label: 'No',
                    onClick: () => {

                    }
                }
            ]
        });

    }

    return (
        <a href={"/"} onClick={remove}>
            <FontAwesomeIcon icon={faTrashAlt} className={"text-pink-800"}/>
        </a>
    )
}

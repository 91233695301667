import React, {useEffect, useState} from "react";

import {useHistory} from 'react-router-dom'
import Field from "../../form/Field";
import {toast} from "react-toastify";
import {NaeApiAuth} from "nae-react-auth";
import LogoBlack from "../../assets/images/logo_black.png"
import AuthMobileAlert from "./AuthMobileAlert";

export default function LoginPage() {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const history = useHistory();

    const goToSignup = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        history.push('/signup');
    }

    const doLogin = () => {
        NaeApiAuth.doLogin(email, password).then((res) => {
            if (res.isError) {
                toast.error(res.error.description)
                return
            }
            history.push('/funnels');
            window.localStorage.setItem('token', res.token)

            try {
                // @ts-ignore
                window.dataLayer.push({
                    'event': 'login'
                });
            } catch (e) {
            }
        })
    }

    const doPasswordReset = (e: React.MouseEvent) => {
        e.preventDefault();
        history.push('/password-reset');
    }

    return (
        <div className={"main-bg h-screen w-screen"}>
            <div className={"h-screen flex justify-center items-center"}>
                <div className={"w-96 px-8 py-10 bg-white shadow-md rounded-md grid gap-2"}>
                    <AuthMobileAlert/>

                    <div className={"text-center mb-4"}>
                        <img src={LogoBlack} width={150} className={"mx-auto mb-8"} alt={""}/>
                        <p className={"text-lg font-medium"}>Welcome back!</p>
                        <p className={"text-sm font-medium"}>Sign up and improve your business</p>
                    </div>

                    <Field title={"Enter email address"} fieldKey={"email"} val={email}
                           onChange={(key: string, val: string) => setEmail(val)}
                           required={true}
                           hideLabel={true}/>

                    <Field title={"Enter password"}
                           fieldKey={"password"}
                           val={password}
                           onChange={(key: string, val: string) => setPassword(val)}
                           type={"password"}
                           required={true}
                           hideLabel={true}
                    />

                    <a href="/password-reset" className={"text-xs ml-4"} onClick={doPasswordReset}>Reset password</a>

                    <button type="button" className={"main-btn mt-4"} onClick={doLogin}>Log in</button>

                    <p className={"text-center mt-4 text-xs"}>New user? <a href="/signup" onClick={goToSignup} className={"text-sm"}>Sign up</a></p>
                </div>

            </div>
        </div>
    )
}

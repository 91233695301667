import React, {Fragment, useEffect, useState} from "react";
import {toast} from "react-toastify";
import {Funnel} from "./CampaignsListPage";
import {BackendConfig} from "../../config/BackendConfig";
import {useHistory, useParams} from 'react-router-dom'
import {v4 as uuidv4} from 'uuid';
import ApiRest from "../../service/ApiRest";
import ApiCampaign from "../../service/ApiCampaign";

import CfLogo from "../../assets/images/external/ClickFunnels-300-150.png"
import DfLogo from "../../assets/images/external/DropFunnels-300-150.png"
import FunnelAirLogo from "../../assets/images/external/Funnelair-300-150.png"
import GumRoadLogo from "../../assets/images/external/gumroad-300-150.png"
import TypeformLogo from "../../assets/images/external/Typeform-300-150.png"
import SystemeLogo from "../../assets/images/external/systeme.png"
import WixLogo from "../../assets/images/external/WixLogo.png"

import FieldSwitch from "../../form/FieldSwitch";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlay, faQuestionCircle} from "@fortawesome/pro-light-svg-icons";

const defFunnel: Funnel = {
    name: '',
    accessToken: '',
    id: 0,
    pixelId: '',
    testEventCode: '',
    url: '',
    lastActivity: '',
    platform: '',
    upsellSelection: 0
}

const platforms = {
    df: 'DropFunnels',
    cf: 'ClickFunnels',
    typeform: 'Typeform',
    funnelair: 'Funnelair',
    gumroad: 'Gumroad',
    systemeio: 'Systeme.io',
    wix: 'WIX'
}

const userGuidePlatformLinks = {
    df: 'user-guide-df',
    cf: 'user-guide-cf',
    typeform: 'user-guide-typeform',
    funnelair: 'user-guide-funnelair',
    gumroad: 'user-guide-gumroad',
    systemeio: 'user-guide-systemeio',
    wix: 'user-guide-wix'
}

interface ParamTypes {
    id: string
}

const blockMargin = 30;

export default function CampaignPage() {
    const history = useHistory();

    const {id} = useParams<ParamTypes>();

    const [step, setStep] = useState(id === 'new' ? 0 : 1);

    const [lastActivity, setLastActivity] = useState('');
    const [platform, setPlatform] = useState('');
    const [upsellSelection, setUpsellSelection] = useState(0);
    const [name, setName] = useState('');
    const [pixelId, setPixelId] = useState('');
    const [accessToken, setAccessToken] = useState('');
    const [url, setUrl] = useState('');
    const [testEventCode, setTestEventCode] = useState('');

    const webhookText = `${BackendConfig.url}/app/webhook/process/${url}`;
    const scriptText = `<script type="text/javascript" src="${BackendConfig.url}/app/public/script/${url}"></script>`;
    const backendScriptText = `import wixData from 'wix-data';
import {fetch} from 'wix-fetch';

export async function wixStores_onNewOrder(event) {
  const newOrderId = event.orderId;
  wixData.get("Stores/Orders", newOrderId).then((results) => {fetch("${webhookText}", {method: "POST", headers: {"Content-Type": "application/json",},body: JSON.stringify(results),})});
}`;

    const doCopy = (e: React.MouseEvent, text: string) => {
        e.preventDefault();

        navigator.clipboard.writeText(text).then(r => {
            toast.success("The text has been copied to the clipboard");
        });
    }

    const doTest = (e: React.MouseEvent) => {
        e.preventDefault();

        if (!pixelId) {
            toast.error('Please enter Facebook Pixel ID');
            return;
        }
        if (!accessToken) {
            toast.error('Please enter Access Token');
            return;
        }
        if (!testEventCode) {
            toast.error('Please enter Test Event Code');
            return;
        }

        ApiCampaign.sendTestEvent(accessToken, pixelId, testEventCode).then((res: any) => {
            if (!!res.error) {
                toast.error(res.error);
                return;
            }
            toast.success("Success!");
        });
    }

    const goBack = (e: React.MouseEvent) => {
        e.preventDefault();
        history.push('/funnels');
    }

    const doSave = (e: React.MouseEvent) => {
        e.preventDefault();

        if (!name) {
            toast.error('Please enter Funnel Name');
            return;
        }
        if (!pixelId) {
            toast.error('Please enter Facebook Pixel ID');
            return;
        }
        if (!accessToken) {
            toast.error('Please enter Access Token');
            return;
        }

        if (id === 'new') {
            ApiRest.addElement('campaign', {
                name,
                url,
                pixelId,
                accessToken,
                testEventCode,
                platform,
                upsellSelection
            }).then((res) => {
                toast.success('Activated');
                history.push('/funnels');
            })
        } else {
            ApiRest.updateElement('campaign', parseInt(id, 10), {
                name,
                pixelId,
                accessToken,
                testEventCode,
                platform,
                upsellSelection
            }).then((res) => {
                toast.success('Activated');
                history.push('/funnels');
            })
        }
    }

    const getData = () => {
        if (id === 'new') {
            setUrl(uuidv4());
        } else {
            ApiRest.getElement('campaign', parseInt(id, 10)).then(res => {
                setName(res.name);
                setPixelId(res.pixelId);
                setAccessToken(res.accessToken);
                setTestEventCode(res.testEventCode);
                setUrl(res.url);
                setPlatform(res.platform);
                setUpsellSelection(res.upsellSelection);
                setLastActivity(res.lastActivity);
            });
        }
    }

    useEffect(getData, [id]);

    const selectPlatform = (e: any, p: string) => {
        e.preventDefault();
        setPlatform(p);
        setStep(1);
    }

    const sep = <div className={"separator"}>&nbsp;</div>

    // @ts-ignore
    const platformText = platform in platforms ? platforms[platform] : '';
    // @ts-ignore
    const userGuideLink = "/" + (platform in userGuidePlatformLinks ? userGuidePlatformLinks[platform] : 'user-guide');

    const linkClass = "border border-gray-200 hover:border-gray-400 p-5 rounded-md flex-grow flex justify-center";
    return (
        <div className={"data-container p-10"}>
            <div className={"bg-white px-10 py-5 shadow-md rounded-md"}>

                {step === 0 ?
                    <Fragment>
                        <div className={"space-y-8 container"}>
                            <p className={"text-xl"}>Select platform</p>
                            <div className={"flex flex-wrap gap-3"}>
                                <a href={"/"} onClick={e => selectPlatform(e, 'wix')} className={linkClass}>
                                    <img alt={"Wix"}
                                         src={WixLogo} style={{maxHeight: 120}}/>
                                </a>
                                <a href={"/"} onClick={e => selectPlatform(e, 'cf')} className={linkClass}>
                                    <img alt={"ClickFunnels"}
                                         src={CfLogo} style={{maxHeight: 120}}/>
                                </a>
                                <a href={"/"} onClick={e => selectPlatform(e, 'df')} className={linkClass}>
                                    <img alt={"DropFunnels"}
                                         src={DfLogo} style={{maxHeight: 120}}/>
                                </a>
                                <a href={"/"} onClick={e => selectPlatform(e, 'funnelair')} className={linkClass}>
                                    <img alt={"Funnelair"}
                                         src={FunnelAirLogo} style={{maxHeight: 120}}/>
                                </a>
                                <a href={"/"} onClick={e => selectPlatform(e, 'typeform')} className={linkClass}>
                                    <img alt={"Typeform"}
                                         src={TypeformLogo} style={{maxHeight: 120}}/>
                                </a>
                                <a href={"/"} onClick={e => selectPlatform(e, 'gumroad')} className={linkClass}>
                                    <img alt={"Gumroad"}
                                         src={GumRoadLogo} style={{maxHeight: 120}}/>
                                </a>
                                <a href={"/"} onClick={e => selectPlatform(e, 'systemeio')} className={linkClass}>
                                    <img alt={"Systeme.io"}
                                         src={SystemeLogo} style={{maxHeight: 120}}/>
                                </a>

                            </div>
                            <p className="flex justify-end">
                                <button className="cancel-btn" onClick={goBack}>Cancel</button>
                                {/*<button type="submit" onClick={doSave}>{"Activate"}</button>*/}
                            </p>
                        </div>
                    </Fragment> :
                    <Fragment>
                        <div className={"space-y-8 container"}>
                            <p className={"text-xl"}>
                                Settings {name && (<Fragment>- {name}</Fragment>)}
                            </p>

                            <div className="space-y-4">
                                <div className={"flex"}>
                                    <label className={"w-72"}>Platform</label>
                                    <p>{platformText}</p>
                                </div>
                                {!!lastActivity &&
                                <div className={"flex"}>
                                    <label className={"w-72"}>Last activity</label>
                                    <p>{lastActivity}</p>
                                </div>
                                }
                                <div className={"flex"}>
                                    <label className={"w-72"}>Name</label>
                                    <span className={"flex-grow"}>
                                        <input type="text" placeholder=""
                                               value={name}
                                               onChange={(e) => setName(e.target.value)}
                                        />
                                    </span>
                                </div>
                                {sep}
                                <div className={"flex"}>
                                    <label className="w-72">Add upsell value to purchase</label>
                                    <span className={"flex-grow"}>
                                        <FieldSwitch setVal={(v) => setUpsellSelection(v ? 1 : 0)}
                                                     val={!!upsellSelection}/>
                                    </span>
                                </div>
                                {sep}
                                <div className={"flex"}>
                                    <label className={"w-72"}>
                                        <a href={userGuideLink + "#section-2"} target={"_blank"}>
                                            <FontAwesomeIcon icon={faQuestionCircle} className={"mr-1"}/>
                                            Facebook Pixel ID
                                        </a>
                                    </label>
                                    <span className={"flex-grow"}>
                                    <input type="text" placeholder=""
                                           value={pixelId}
                                           onChange={(e) => setPixelId(e.target.value)}/>
                                    </span>
                                </div>

                                <div className={"flex"}>
                                    <label className={"w-72"}>
                                        <a href={userGuideLink + "#section-3"} target={"_blank"}>
                                            <FontAwesomeIcon icon={faQuestionCircle} className={"mr-1"}/>
                                            Access Token
                                        </a>
                                    </label>
                                    <span className={"flex-grow"}>
                                    <input type="text" placeholder=""
                                           value={accessToken}
                                           onChange={(e) => setAccessToken(e.target.value)}
                                    />
                                    </span>
                                </div>
                                <div className={"flex"}>
                                    <label className={"w-72"}>
                                        <a href={userGuideLink + "#section-4"} target={"_blank"}>
                                            <FontAwesomeIcon icon={faQuestionCircle} className={"mr-1"}/>
                                            Test event
                                        </a>
                                    </label>

                                    <span className={"flex-grow"}>
                                    <input type="text" placeholder=""
                                           value={testEventCode}
                                           onChange={(e) => setTestEventCode(e.target.value)}

                                    />
                                    </span>

                                    <button className={"fill-btn ml-2"} onClick={doTest}>
                                        <FontAwesomeIcon icon={faPlay} className={"mr-2"}/> Test Event
                                    </button>

                                </div>
                                {sep}

                                <p className={"flex"}>
                                    <label className={"w-72"}>
                                        <a href={userGuideLink + "#section-5"} target={"_blank"}>
                                            <FontAwesomeIcon icon={faQuestionCircle} className={"mr-1"}/>
                                            Webhook
                                        </a>
                                    </label>
                                    <span className={"flex-grow"}>
                                        <input type="text" placeholder="" readOnly={true} value={webhookText}/>
                                    </span>
                                    <button onClick={(e) => doCopy(e, webhookText)} className="fill-btn ml-2">Copy
                                    </button>
                                </p>
                                <p className={"flex"}>
                                    <label className={"w-72"}>
                                        <a href={userGuideLink + "#section-6"} target={"_blank"}>
                                            <FontAwesomeIcon icon={faQuestionCircle} className={"mr-1"}/>
                                            Script
                                        </a>
                                    </label>
                                    <span className={"flex-grow"}>
                                        <input type="text" placeholder="" readOnly={true} value={scriptText}/>
                                    </span>
                                    <button onClick={(e) => doCopy(e, scriptText)} className="fill-btn ml-2">Copy
                                    </button>
                                </p>
                                {platform === 'wix' && <p className={"flex"}>
                                    <label className={"w-72"}>
                                        <a href={userGuideLink + "#section-6"} target={"_blank"}>
                                            <FontAwesomeIcon icon={faQuestionCircle} className={"mr-1"}/>
                                            Backend event
                                        </a>
                                    </label>
                                    <span className={"flex-grow"}>
                                        <textarea readOnly={true} value={backendScriptText}/>
                                    </span>
                                    <button onClick={(e) => doCopy(e, backendScriptText)} className="fill-btn ml-2">Copy
                                    </button>
                                </p>
                                }
                            </div>
                            <p className="flex justify-end gap-2">
                                <button className={"cancel-btn"} onClick={goBack}>Cancel</button>
                                <button className={"main-btn"} onClick={doSave}>{"Activate"}</button>
                            </p>
                        </div>
                    </Fragment>
                }
            </div>
        </div>
    )
}

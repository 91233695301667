import React, {Fragment, useEffect, useState} from "react";
import {loadStripe} from '@stripe/stripe-js';
import {BackendConfig} from "../../config/BackendConfig";
import {CardElement, Elements} from "@stripe/react-stripe-js";
import StripeDropton from '../../assets/images/stripedropton.png';
import PaymentCheckoutBtn from "./PaymentCheckoutBtn";
// import {useDispatch, useSelector} from "react-redux";

const stripePromise = loadStripe(BackendConfig.stripePkKey);

interface Props {
    isValidSubscription: boolean,
    containerClassName?: string
}

export default function PaymentPage(props: Props) {
    // const dispatch = useDispatch();

    const [isOpen, setIsOpen] = useState(!props.isValidSubscription);
    const [mode, setMode] = useState(-1);
    const needPayment = false;
    // useSelector((state: any) => state.Auth.needPayment)

    useEffect(() => {
        if (needPayment) {
            setIsOpen(needPayment);
        } else {
            setIsOpen(!props.isValidSubscription);
        }

    }, [props.isValidSubscription, needPayment]);

    const closeBlock = () => {
        // dispatch({type: 'CLOSE_NEED_PAYMENT'});
    }

    const colClass = "border border-gray-200 hover:border-green-500 p-5 rounded-md grid gap-2 relative border-2 cursor-pointer";
    return <Fragment>
        <div className={props.containerClassName ? props.containerClassName : "data-container" + " p-10"}>
            <div className={"bg-white px-10 py-5 shadow-md rounded-md"}>

                <Elements stripe={stripePromise}>
                    <div className={"grid gap-4"}>
                        <p className="text-xl">{!props.isValidSubscription ? 'Subscribe to Resume Account' : 'Subscribe'}</p>
                        {!props.isValidSubscription &&
                        <p>Unfortunately your 7-days free trial expired. To continue using
                            Capibox please subscribe</p>}


                        <div className={"grid grid-cols-2 gap-4"}>
                            <div className={colClass + " " + (mode === 0 ? 'border-green-500' : '')}
                                 onClick={() => setMode(0)}>
                                <span
                                    className={"text-green-500 absolute -top-2.5 px-4 bg-white left-1/2 transform -translate-x-1/2"}>32% OFF</span>
                                <span
                                    className={"text-green-500 absolute -bottom-2.5 px-4 bg-white left-1/2 transform -translate-x-1/2"}>Save 189$</span>
                                <p>
                                    <span className={"text-xl mr-2"}>Annual</span>

                                    33.25 USD / month
                                </p>
                                <p className="text-gray-500">
                                    $399.00 USD billed every year
                                </p>
                            </div>
                            <div className={colClass + " " + (mode === 1 ? 'border-green-500' : '')}
                                 onClick={() => setMode(1)}>
                                <p>
                                    <span className={"text-xl mr-2"}>Monthly</span>

                                    49.00 USD / month
                                </p>
                                <p className="text-gray-500	">
                                    49.00 USD billed every month
                                </p>

                            </div>
                        </div>

                        {mode > -1 &&

                        <Fragment>
                            <p className="text-xl">Add credit card</p>

                            <div className={"stripe-placeholder"}>
                                <CardElement
                                    options={{
                                        style: {
                                            base: {
                                                fontSize: '16px',
                                                color: '#32325d',
                                                '::placeholder': {
                                                    color: '#aab7c4',
                                                },
                                            },
                                            invalid: {
                                                color: '#fa755a',
                                                iconColor: '#fa755a',
                                            },
                                        },
                                    }}
                                />
                            </div>
                        </Fragment>
                        }
                        <p className="flex">
                            <PaymentCheckoutBtn mode={mode}/>
                        </p>
                        {props.isValidSubscription &&
                        <div className="text-center mt-2">
                            <button className={"btn btn-link"} onClick={closeBlock}>Cancel</button>
                        </div>}
                    </div>
                </Elements>
            </div>
        </div>
        {(mode === 0 || mode === 1) &&
        <div className={"mt-2 grid gap-2"}>
            {mode === 0 &&
            <Fragment>
                <p className="text-center text-gray-500">Your new plan starts today and will be
                    renewed automatically
                    next
                    year.
                    <br/>
                    Subscription can be canceled at any time.
                </p>

            </Fragment>
            }

            {mode === 1 &&
            <Fragment>
                <p className={"text-center text-gray-500"}>Your new plan starts today and will be
                    renewed
                    automatically next
                    month.<br/>Subscription can be canceled at any time.
                </p>
            </Fragment>
            }
            <p className={"flex justify-center"}>
                <img src={StripeDropton} style={{width: 180}} alt={""}/>
            </p>
        </div>
        }
    </Fragment>
}

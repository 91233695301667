import React, {Fragment, useContext, useEffect, useState} from "react";
import {Redirect, useHistory} from "react-router-dom";
// import useLocalStorage from "../../Hooks/useLocalStorage";
// import ApiProfile from "../../service/ApiProfile";
import useLocalStorage from "../../Hooks/useLocalStorage";
import ApiProfile from "../../service/ApiProfile";
import AppLayout from "../../pages/app/AppLayout";

export interface User {
    email: string,
    valid: boolean,
    hasSubscription: boolean,
}

interface ProviderValue {
    user: User
}

const defUser = {
    email: '',
    valid: false,
    hasSubscription: false,
}

export const UserContext = React.createContext<ProviderValue>({
    user: defUser
})
export const useUser = () => useContext(UserContext);

interface Props {
    children: any,
}

export const UserProvider = (props: Props) => {
    const [loaded, setLoaded] = useState(false);
    const [user, setUser] = useState(defUser);
    const [token] = useLocalStorage('token', '');
    const history = useHistory();

    const navigate = (e: any, route: string) => {
        e.preventDefault();
        history.push('/' + route);
    }

    const getData = () => {
        ApiProfile.getProfile().then(res => {
            if (!!res.isError) {
                history.push('/login');
                return;
            }
            setUser(res);
            setLoaded(true);
        }).catch(e => {
            history.push('/login');
        })
    }
    useEffect(getData, [token, history]);

    if (!loaded) {
        return <Fragment/>
    }

    return (
        <UserContext.Provider value={{
            user
        }}>
            {!!user.email ? <AppLayout>
                {props.children}
            </AppLayout> : <Redirect to={"/login"}/>}
        </UserContext.Provider>

    )
}

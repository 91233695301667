import React, {Fragment, useEffect, useState} from "react";
import {loadStripe} from '@stripe/stripe-js';
import {BackendConfig} from "../../config/BackendConfig";
import {CardElement, Elements} from "@stripe/react-stripe-js";
import StripeDropton from '../../assets/images/stripedropton.png';
import PaymentSubscriptionCheckoutBtn from "./PaymentSubscriptionCheckoutBtn";
// import {useDispatch, useSelector} from "react-redux";

const stripePromise = loadStripe(BackendConfig.stripePkKey);

interface Props {
    containerClassName?: string,
    password: string,
    passwordRepeat: string,
    email: string,
    backToStep1: () => void
}

export default function PaymentSubscriptionPage(props: Props) {
    const [mode, setMode] = useState(-1);

    const colClass = "border border-gray-200 hover:border-green-500 p-5 rounded-md grid gap-2 relative border-2 cursor-pointer";
    return <Fragment>
        <div className={" "}>
            <div className={""}>

                <Elements stripe={stripePromise}>
                    <div className={"grid gap-4"}>

                        <div className={"grid grid-cols-2 gap-4"}>
                            <div className={colClass + " " + (mode === 0 ? 'border-green-500' : '')}
                                 onClick={() => setMode(0)}>
                                <span
                                    className={"text-green-500 absolute -top-2.5 px-4 bg-white left-1/2 transform -translate-x-1/2"}>32% OFF</span>
                                <span
                                    className={"text-green-500 absolute -bottom-2.5 px-4 bg-white left-1/2 transform -translate-x-1/2"}>Save 189$</span>
                                <p>
                                    <span className={"text-xl mr-2"}>Annual</span>

                                    33.25 USD / month
                                </p>
                                <p className="text-gray-500">
                                    $399.00 USD billed every year
                                </p>
                            </div>
                            <div className={colClass + " " + (mode === 1 ? 'border-green-500' : '')}
                                 onClick={() => setMode(1)}>
                                <p>
                                    <span className={"text-xl mr-2"}>Monthly</span>

                                    49.00 USD / month
                                </p>
                                <p className="text-gray-500	">
                                    49.00 USD billed every month
                                </p>

                            </div>
                        </div>

                        {mode > -1 &&

                        <Fragment>
                            <p className="text-xl">Add credit card</p>

                            <div className={"stripe-placeholder"}>
                                <CardElement
                                    options={{
                                        style: {
                                            base: {
                                                fontSize: '16px',
                                                color: '#32325d',
                                                '::placeholder': {
                                                    color: '#aab7c4',
                                                },
                                            },
                                            invalid: {
                                                color: '#fa755a',
                                                iconColor: '#fa755a',
                                            },
                                        },
                                    }}
                                />
                            </div>
                        </Fragment>
                        }
                        <p className="flex">
                            <PaymentSubscriptionCheckoutBtn backToStep1={props.backToStep1} mode={mode} email={props.email} password={props.password} passwordRepeat={props.passwordRepeat}/>
                        </p>

                    </div>
                </Elements>
            </div>
        </div>
        {(mode === 0 || mode === 1) &&
        <div className={"mt-2 grid gap-2"}>
            {mode === 0 &&
            <Fragment>
                <p className="text-center text-gray-500">
                    You subscription plan will automatically start after 14 day FREE trial and will be renewed next year.
                    <br/>
                    Subscription can be canceled at any time.
                </p>

            </Fragment>
            }

            {mode === 1 &&
            <Fragment>
                <p className={"text-center text-gray-500"}>
                    You subscription plan will automatically start after 14 day FREE trial and will be renewed next month.
                    <br/>Subscription can be canceled at any time.
                </p>
            </Fragment>
            }
            <p className={"flex justify-center"}>
                <img src={StripeDropton} style={{width: 180}} alt={""}/>
            </p>
        </div>
        }
    </Fragment>
}

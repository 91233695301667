import React, {Fragment} from "react";
import {IDataSubscription, IProfileData} from "./PaymentsPage";
import ApiProfile from "../../service/ApiProfile";
import {toast} from "react-toastify";

interface Props {
    data: IProfileData,
    getData: () => void,
}

export default function PaymentNextPayment(props: Props) {
    const {data, getData} = props;

    return (
        <Fragment>
            <p className={"text-xl"}>Next payment</p>
            <div className={"grid gap-2"}>
                {data.subscriptions.map((item: IDataSubscription) => {
                    return (
                        <div key={"sub-" + item.id}>
                            {item.status === 'active' && !item.cancel_at_period_end ? <Fragment>
                                Your plan will automatically renew on {item.endDate} and you will charged
                                ${item.amount.toFixed(2)}.
                            </Fragment> : <Fragment>
                                Plan active till {item.endDate}
                            </Fragment>}
                        </div>
                    )
                })}
            </div>
        </Fragment>
    )
}

import React, {Fragment, useEffect, useState} from "react";
import ApiProfile from "../../service/ApiProfile";
import AccountPage from "./Account/AccountPage";
import PaymentCardPage from "./PaymentCardPage";
import PaymentNextPayment from "./PaymentNextPayment";
import PaymentCancelAccount from "./PaymentCancelAccount";
import PaymentsFreeTrial from "./PaymentsFreeTrial";
import PaymentInvoices from "./PaymentInvoices";

export interface IDataInvoice {
    id: number,
    pdf: string,
    date: string,
    number: string,
    amount: number,
    status: string
}

export interface IDataCard {
    brand: string,
    last4: number,
    exp_month: number,
    exp_year: number
}

export interface IProfileData {
    invoices: IDataInvoice[],
    cards: IDataCard[],
    subscriptions: IDataSubscription[],
    valid: boolean,
    validTill: string,
    email: string,
}

export interface IDataSubscription {
    id: number,
    endDate: string,
    amount: number,
    status: string,
    cancel_at_period_end: boolean,
}

export default function PaymentsPage() {
    const [data, setData] = useState<IProfileData>();

    const getData = () => {
        ApiProfile.getPayments().then(res => {
            setData(res.data);
        })
    }

    useEffect(getData, []);

    const hasActiveSubscriptions = !!data && (data.subscriptions.length > 0 && data.subscriptions.filter(f => f.status === 'trialing').length === 0);

    return (
        <div className={"data-container p-10"}>

            {!!data && <Fragment>
                <div className={"bg-white px-10 py-5 shadow-md rounded-md"}>
                    <div className={"grid gap-4"}>
                        <AccountPage data={data}/>

                        {hasActiveSubscriptions &&
                        <Fragment>
                            <hr/>
                            <PaymentCardPage data={data}/>
                            <hr/>
                            <PaymentNextPayment data={data} getData={getData}/>
                        </Fragment>
                        }

                        {!hasActiveSubscriptions &&
                        <Fragment>
                            <hr/>
                            <PaymentsFreeTrial data={data}/>
                        </Fragment>
                        }

                        {(data.cards.length > 0 || data.subscriptions.length > 0) &&
                        <Fragment>
                            <hr/>
                            <PaymentInvoices data={data}/>

                        </Fragment>
                        }
                    </div>

                </div>
                {(data.cards.length > 0 || data.subscriptions.length > 0) &&
                <div className={"mt-4"}>
                    <PaymentCancelAccount data={data} getData={getData}/>
                </div>
                }
            </Fragment>}

        </div>
    )
}

import React from "react";

interface Props {
    val: boolean,
    setVal: (val: boolean) => void,
}

export default function FieldSwitch({val, setVal}: Props) {
    return (
        <span className="flex input-switch " onClick={() => setVal(!val)}>
            <input type="checkbox" checked={val} onChange={() => {
            }}/>
            <label className={"pl-6"}>
                <span className={val ? "hidden" : ''}>Off</span>
                <span className={!val ? "hidden" : ''}>On</span>
            </label>
        </span>
    )
}

import React from "react";
import {useHistory} from 'react-router-dom'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/pro-solid-svg-icons";

interface Props {
    textRight?: boolean
}

export default function NewButton(props: Props) {
    const history = useHistory();
    const addNew = (e?: React.MouseEvent) => {
        if (e) {
            e.preventDefault();
        }
        history.push('/funnels/new');
    }

    return (

        <button className={"main-btn"} onClick={addNew}>
            <FontAwesomeIcon icon={faPlus} className={"mr-2"}/>
            Connect funnel
        </button>

    )
}

import React, {Fragment} from "react";
import {isBrowser} from 'react-device-detect';


export default function AuthMobileAlert() {
    if (isBrowser) {
        return <Fragment/>
    }
    return (
        <div className={"mb-4 p-2 bg-yellow-100"}>
            IMPORTANT<br/>
            For easy ant smooth Capibox integration,<br/>
            please use Desktop version of your browser
        </div>
    )
}

import React, {useState} from "react";
import ApiProfile from "../service/ApiProfile";
import {toast} from "react-toastify";

export default function SendFeedbackPage() {
    const [subject, setSubject] = useState('');
    const [body, setBody] = useState('');

    const doSend = (e: React.MouseEvent) => {
        e.preventDefault();

        if (!subject) {
            toast.error('Please fill subject');
            return;
        }
        if (!body) {
            toast.error('Please fill body');
            return;
        }
        ApiProfile.sendFeedback(subject, body).then(res => {
            toast.success("Feedback has been sent");

            setSubject('');
            setBody('');
        });
    }

    return (
        <div className={"container p-10"}>
            <div className={"bg-white px-10 py-5 shadow-md rounded-md"}>
                <div className={"space-y-8 container"}>

                    <div className={"space-y-2"}>
                        <p className={"text-xl"}>Contact US</p>
                        <p className={"font-bold"}>We would love to hear from you</p>
                    </div>


                    <div className={"space-y-2"}>
                        <p className={"flex"}>
                            <label className={"w-40"}>Subject</label>
                            <input type="text" placeholder="" value={subject}
                                   onChange={(e) => setSubject(e.target.value)}/>
                        </p>
                        <p className={"flex"}>
                            <label className={"w-40"}>Body</label>
                            <textarea value={body} onChange={(e) => setBody(e.target.value)} rows={10}/>
                        </p>
                    </div>

                    <p className={"flex justify-end"}>
                        <button type="submit" className={"main-btn"} onClick={doSend}>{"Send"}</button>
                    </p>

                </div>
            </div>
        </div>
    )
}
